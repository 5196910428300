<template>

  <ModalComponent title="Documento Policia">
    <template #button>
      <button class="btn btn-sm grey darken-4 white-text font-800">
        <i class="fa-solid fa-building-shield me-1"></i> Policia
      </button>
    </template>

    <div>
      <div class="font-400 white-text mb-3">
        <div class="mb-3 font-600">
          Información del Hotel
        </div>

        <div class="mb-2">
          <label>Codigo Hotel</label>
          <input v-model="hotelInfo.nif"
                 placeholder="Ej: 00000AAH02"
                 class="custom-input w-100 grey darken-3 white-text">
        </div>

        <div class="mb-2">
          <label>Nombre del hotel</label>
          <input v-model="hotelInfo.name"
                 class="custom-input w-100 grey darken-3 white-text">
        </div>

        <div class="mb-2">
          <label>Numero Serie</label>
          <input v-model="hotelInfo.numero_serie"
                 class="custom-input w-100 grey darken-3 white-text">
        </div>
      </div>

      <hr/>

      <button @click="generateAndDownloadFile" class="btn btn-sm grey darken-3 white-text font-800">
        <i class="fa-solid fa-download me-1"></i> Generar Documento
      </button>

    </div>
  </ModalComponent>

</template>

<script>

import ModalComponent from "@/components/ModalComponent.vue";
import {format} from "@formkit/tempo";
import {countryCodesList} from "@/countryCodes";

export default {
  components: {ModalComponent},

  props: ['data'],

  data() {
    return {
      hotelInfo: {
        nif: '',
        name: '',
        numero_serie: '000',
      }
    };
  },

  name: 'ModalPolicia',

  watch: {
    hotelInfo: {
      handler(val) {
        // Save to localstorage
        localStorage.setItem('hotelInfo', JSON.stringify(val));
      },
      deep: true
    }
  },

  computed: {
    content() {
      // Formatear la hora actual en formato 11:50 -> 1150
      const date = new Date();
      const hora = format(date, 'HHmm');
      const fecha = format(date, 'YYYYMMDD');

      let string = `1|${this.hotelInfo.nif}|${this.hotelInfo.name}|${fecha}|${hora}|${Object.keys(this.data).length}|\n`;

      Object.keys(this.data).forEach((key) => {
        const item = this.data[key];
        // Format 2|DNI|Passport|Tipo Documento|Fecha Expedicion|Apellido1|Apellido2|Nombre|Sexo|Fecha Nacimiento|Nacionalidad|Fecha Entrada

        const nacimiento = format(item.birth_date, 'YYYYMMDD');
        const expedicion = format(item.document_issue, 'YYYYMMDD');
        const tipoDocumento = this.calcularTipoDocumento(item);

        if (tipoDocumento === 'D') {
          string += `2|${item.document_number}||${tipoDocumento}`;
        } else {
          string += `2||${item.document_number}|${tipoDocumento}`;
        }

        const nacionalidad = countryCodesList[item.nationality]?.toUpperCase() ?? '';

        string += `|${expedicion}|${item.last_name}|${item.second_last_name}|${item.first_name}|${item.gender}|${nacimiento}|${nacionalidad}|${fecha}\n`;
      });

      return string;
    }
  },

  methods: {
    calcularTipoDocumento(data){
      if (data.document_type === 'I' && data.nationality === 'ES') {
        return 'D'; // DNI Español
      } else if (data.document_type === 'I' && data.nationality !== 'ES') {
        return 'I'; // ID No español
      } else if (data.document_type === 'P') {
        return 'P'; // Pasaporte
      } else if (data.document_type === 'N') {
        return 'N'; // Permiso de residencia
      }else if (data.document_type === 'C') {
        return 'C'; // Permiso de residencia
      }
    },
    generateAndDownloadFile() {
      const filename = `${this.hotelInfo.nif}.${this.hotelInfo.numero_serie}`;
      const blob = new Blob([this.content], {type: 'text/plain'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.incrementarNumeroSerie();
    },
    incrementarNumeroSerie(){
      // Increment numero de serie manteniendo los 000
      this.hotelInfo.numero_serie = (parseInt(this.hotelInfo.numero_serie) + 1).toString().padStart(3, '0');
    }
  },

  created() {
    // Load from localstorage
    const hotelInfo = localStorage.getItem('hotelInfo');
    if (hotelInfo) {
      this.hotelInfo = JSON.parse(hotelInfo);
    }
  }

}


</script>
