<template>
    <div @click="visible = !visible"
         style="display: inline; width: 100%">
      <slot name="button"/>
    </div>

    <div :id="id"
         class="position-fixed vue-modal-back p-3 native-modal-component"
         v-bind:class="{ active: visible }">
      <div v-bind:class="{ active: visible }"
           class="vue-modal position-relative shadow grey darken-4">
        <div class="clearfix white-text p-3 pb-0">
          <div class="float-start">
            <h6 class="font-600 mb-0">{{ title }}</h6>
          </div>
          <div class="float-end">
            <i
                class="fa-regular fa-circle-xmark cursor-pointer"
                @click="visible = !visible"
                style="font-size: 18px; margin-top: 2px"
            ></i>
          </div>
        </div>
        <hr/>
        <div class="overflow-auto ps-3 pe-3 pt-1 pb-1"
             style="max-height: 80vh">
          <slot/>
        </div>
        <div class="pb-3"></div>
      </div>
    </div>
</template>

<script>
export default {
  components: {},

  props: ["title"],

  emits: ["openModal", "closeModal"],

  name: "ModalComponent",

  data() {
    return {
      visible: false
    };
  },

  watch: {
    visible() {
      if (this.visible) {
        setTimeout(() => {
          document.addEventListener("click", this.handleClick);
          document.addEventListener("touchstart", this.handleClick);
          document.addEventListener("keyup", this.handleClick);
        }, 400);
      } else {
        document.removeEventListener("click", this.handleClick);
        document.removeEventListener("touchstart", this.handleClick);
        document.removeEventListener("keyup", this.handleClick);
      }
    },
  },

  computed: {
    id: () => 'modal-' + Math.random().toString(36).substring(10)
  },

  methods: {
    closeModal() {
      this.visible = false;
      this.$emit("closeModal");
    },
    openModal() {
      this.visible = true;
      this.$emit("openModal");
    },
    handleClick(e) {
      if (e.target.classList.contains("vue-modal-back")) {
        this.closeModal();
      }
      if (e.key === "Escape") {
        this.closeModal();
      }
    }
  }
};
</script>

<style scoped>
.vue-modal-back {
  background-color: rgba(0, 0, 0, 0);
  visibility: hidden;
  transition: ease 300ms;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  white-space: normal;
}

.vue-modal-back.active {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.vue-modal {
  z-index: 999;
  top: -100vh;
  width: 550px;
  max-width: 100%;
  border: none !important;
  visibility: hidden;
  margin: auto;
  background-color: white;
  transition: ease 400ms;
}

.vue-modal.active {
  top: 0px;
  visibility: visible;
  border-radius: 10px;
}
</style>
