<template>

    <div>
        <div class="mb-2">
            <div class="position-relative"
                 style="border-radius: 15px; overflow: hidden; width: 100%">
                <video
                    style="display: block"
                    autoplay
                    id="video"
                    width="100%"
                    muted></video>

                <!-- Overlay -->
                <div class="position-absolute"
                     style="top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,0.2)">
                    <div class="h-100 w-100"
                         style="background-position: center center; background-size: cover; background-image: url(https://private-sethotels.ams3.cdn.digitaloceanspaces.com/assets/scan_document.png)"></div>
                </div>
            </div>
        </div>
        <div>
            <button type="button" @click="takepicture"
                    style="height: 35px"
                    class="btn btn-sm grey darken-4 white-text font-600 w-100">
                <i class="fas fa-camera"></i>
            </button>
        </div>

        <canvas id="canvas" style="display: none"></canvas>
    </div>
</template>

<script>

    export default {

        props: [],

        name: 'WebCam',

        data() {
            return {
                streaming: false,
                video: false,
                canvas: false,
                photo: false,
                startbutton: false,
                width: 1920,
                height: 1080,
                blob: null
            };
        },

        computed: {},

        methods: {
            takepicture() {
                this.canvas.width = this.width;
                this.canvas.height = this.height;
                let canvas = document.querySelector('#canvas');
                canvas.getContext('2d').drawImage(this.video, 0, 0, this.width, this.height);
                this.blob = this.canvas.toDataURL('image/jpeg', 1.0);
                this.$emit('foto', this.blob)
            },
            arracarCamara() {
                const videoOptions =  {
                    width: { ideal: 4096 },
                    height: { ideal: 2160 }
                } ;

                if (navigator.mediaDevices.getUserMedia) {
                    navigator.mediaDevices.getUserMedia({video: videoOptions})
                        .then(stream => {
                            this.video.srcObject = stream;
                        })
                        .catch(() => {
                            console.log("Something went wrong! ");
                        });
                }
            }
        },

        mounted() {
            this.video = document.querySelector('#video');
            this.canvas = document.querySelector('#canvas');
            this.photo = document.querySelector('#photo');
            this.startbutton = document.querySelector('#startbutton');
        },

        created() {
            this.arracarCamara();
        }

    };


</script>
