<template>
  <button @click="downloadCsv" class="btn btn-sm grey darken-4 white-text font-800 ms-1">
    <i class="fa-solid fa-file-csv me-1"></i> Generar CSV
  </button>
</template>

<script>


export default {
  props: ['data'],

  name: 'DownloadCsv',

  methods:{
    downloadCsv(){
      const arrayOfObjects = this.data;

      if (arrayOfObjects.length === 0) {
        console.error('Array is empty');
        return;
      }

      // Extract headers
      const headers = Object.keys(arrayOfObjects[0]);
      const csvRows = [];

      // Add the headers as the first row
      csvRows.push(headers.join(','));

      // Add each object as a row
      for (const obj of arrayOfObjects) {
        const values = headers.map(header => {
          // Handle null or undefined values
          const value = obj[header] === null || obj[header] === undefined ? '' : obj[header];
          // Escape double quotes in values
          return `"${value.toString().replace(/"/g, '""')}"`;
        });
        csvRows.push(values.join(','));
      }

      // Join all rows with new line character
      const csvContent = csvRows.join('\n');

      // Create a Blob from the CSV string
      const blob = new Blob([csvContent], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement("a");

      // Create a URL for the Blob and set it as the href attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);

      // Set the download attribute with the desired file name
      link.setAttribute("download", 'export.csv');

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  }

}
</script>