<template>

  <DocumentScannerDemo/>

</template>

<script>

import DocumentScannerDemo from "@/components/Scanner/DocumentScannerDemo";

export default {
  components: {DocumentScannerDemo},
  name: 'HomeView',
}

</script>
