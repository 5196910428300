<template>

  <div class="ms-3">
    <section class="grey darken-4 white-text border-radius-15 p-3">
      <section class="font-800 mb-2 font-16">
        Instrucciones
      </section>
      <section class="mb-3">
        <span class="font-800">1.</span> Elija el tipo de documento que desea escanear, ID o Pasaporte.
      </section>
      <section class="mb-3">
        <span class="font-800">2.</span> Haga foto de las caras del documento que se indican.
      </section>
      <section class="mb-3">
        <span class="font-800">3.</span> Enviar, se cargarán los datos en la tabla inferior.
      </section>
      <section class="mb-3">
        <div class="font-600 font-16">DNI/ID</div>
        <div>
          Centrar el documento y escanear ambas caras del documento de forma que el texto este recto en la imagen y
          sea leible todo el documento
        </div>
      </section>
      <section>
        <div class="font-600 font-16">Pasaporte</div>
        <div>
          Centrar el documento y escanear, asegurese que todo el documento sea visible para que se pueda extraer
          toda la inforamción
        </div>
      </section>
    </section>
  </div>

</template>

<script>

export default {

  name: 'InstruccionesView',

}

</script>
