<template>

    <div class="white p-3"
         id="sideNav"
         style="position: fixed; height: 100vh; width: 300px; border-radius: 0px 20px 20px 0px; z-index: 999">
        <div class="clearfi mb-5">
            <i @click="toggleNavigation" class="fa-solid fa-xmark float-end font-30 pe-1 blue-grey-text text-darken-2 cursor-pointer"></i>
        </div>
    </div>

    <div v-if="loading"
         class="position-fixed blue-grey blue-grey-text text-darken-2 lighten-5"
         style="width: 100vw; height: 100vh">
        <div class="vertical-align-parent w-100" style="height: 100vh">
            <div class="vertical-align-element">
                <div class="m-auto" style="width: fit-content">
                    <JellyTriangle/>
                </div>
            </div>
        </div>
    </div>

    <div class="blue-grey-text text-darken-2"
         style="min-height: 100vh; background-color: #050609">

        <!-- Top Bar -->
        <div class="clearfix w-100 p-3">
            <div class="float-start ps-2" style="line-height: 45px"></div>
            <div class="float-end"></div>
        </div>

        <!-- Pantalla -->
        <div class="d-flex">
            <!-- Contenido -->
            <div class="w-100 pb-3 pe-3 ps-3">
                <div class="h-100 container ps-0 pe-0">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import JellyTriangle from "@/components/Loaders/JellyTriangle";
    import {toggleNav} from "@/functions";

    export default {

        name: 'App',

        components: { JellyTriangle},

        data() {
            return {}
        },

        methods: {
            toggleNavigation(){
                toggleNav();
            }
        }
    }

</script>

<style lang="scss" rel="stylesheet/scss"></style>