<template>
  <div>
    <div class="mb-3">
      <button @click="type = 'id'"
              v-bind:class="{'white': type === 'id', 'grey darken-4 white-text': type !== 'id'}"
              class="btn btn-sm font-800 me-2">
        Documento Identidad
      </button>

      <button @click="type = 'passport'"
              v-bind:class="{'white': type === 'passport', 'grey darken-4 white-text': type !== 'passport'}"
              class="btn btn-sm font-800 me-2">
        Pasaporte
      </button>

      <input
          v-model="apiKey"
          class="custom-input grey darken-4 white-text"
          placeholder="API KEY">
    </div>

    <div class="mb-3 d-flex">
      <WebCam class="w-75 flex-shrink-0 flex-grow-0"
              v-on:foto="takePhoto($event)"/>

      <!-- ID -->
      <div class="ms-3" v-if="type === 'id' && images[0] !== null">
        <div style="border-radius: 15px; overflow: hidden; min-height: 50px"
             class="position-relative mb-3">
          <img :src="images[0]">

          <div class="position-absolute" style="top: 10px; left: 12px">
            <span class="pill grey lighten-4 grey-text text-darken-4">
              <i v-if="this.index_photo === 0"
                 class="fa-regular fa-circle-dot fa-fade green-text me-1"></i>
              Foto Frontal
            </span>
          </div>
        </div>

        <div style="border-radius: 15px; overflow: hidden; min-height: 150px"
             class="position-relative grey darken-4">
          <img :src="images[1]">

          <div class="position-absolute" style="top: 10px; left: 12px">
            <span class="pill grey lighten-4 grey-text text-darken-4">
              <i v-if="this.index_photo === 1" class="fa-regular fa-circle-dot green-text me-1"></i>
              Foto Trasera
            </span>
          </div>
        </div>
      </div>

      <!-- Passport -->
      <div class="ms-3" v-else-if="type === 'passport' && images[0] !== null">
        <div style="border-radius: 15px; overflow: hidden; min-height: 50px" class="position-relative mb-3">
          <img :src="images[0]">

          <div class="position-absolute" style="top: 10px; left: 12px">
            <span class="pill grey lighten-4 grey-text text-darken-4">
              <i v-if="this.index_photo === 0" class="fa-regular fa-circle-dot green-text me-1"></i>
              Foto
            </span>
          </div>
        </div>
      </div>

      <!-- Instrucciones -->
      <InstruccionesView v-else/>

    </div>

    <div class="mb-3">
      <button v-if="status === 0"
              @click="sentOcr"
              :disabled="!canSend"
              class="btn btn-sm grey darken-4 white-text font-700">
        <i class="fa-solid fa-id-card me-1"></i> Enviar
      </button>

      <button v-else-if="status === 1"
              disabled
              class="btn btn-sm grey lighten-4 grey-text text-darken-2 font-700">
        <i class="fa-solid fa-spinner fa-spin me-1"></i> Procesando
      </button>

      <button v-if="status === 2"
              @click="sentOcr"
              class="btn btn-sm green lighten-4 green-text text-darken-2 font-700">
        <i class="fa-solid fa-id-card me-1"></i> Enviado!
      </button>

      <button v-else-if="status === 3"
              @click="sentOcr"
              class="btn btn-sm red lighten-4 red-text text-darken-2 font-700">
        <i class="fa-solid fa-id-card me-1"></i> Error!
      </button>

      <button @click="restore"
              class="btn btn-sm grey darken-4 white-text font-800 ms-1">
        <i class="fa-solid fa-arrow-rotate-left"></i>
      </button>
    </div>

    <div class="red-text text-darken-2 font-600"
         v-if="errorMessage !== null">
      {{ errorMessage }}
    </div>

    <!-- Datos Escaneados -->

    <hr/>

    <div v-if="data !== null">

      <div class="mb-3 white-text font-800 font-16">
        Documentos Escaneados
      </div>

      <div class="mb-3 position-relative">
        <ModalPolicia
            :data="data" />

        <DownloadCsv
            :data="data" />

        <button @click="limpiar"
                class="btn btn-sm grey darken-4 white-text font-800 ms-1 float-end">
          <i class="fa-solid fa-arrow-rotate-left me-1"></i> Limpiar
        </button>

        <button @click="addLine"
                class="btn btn-sm grey darken-4 white-text font-800 ms-1 float-end">
          <i class="fa-solid fa-plus me-1"></i> Linea
        </button>
      </div>

      <div class="table-component pb-3">
        <table class="no-wrap white-text">
          <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Segundo Apellido</th>
            <th>Ciudad</th>
            <th>Direccion</th>
            <th>Pais</th>
            <th>Tipo Documento</th>
            <th>Numero Documento</th>
            <th>Expedicion</th>
            <th>Expiracion</th>
            <th>Nacionalidad</th>
            <th>Sexo</th>
            <th>Nacimiento</th>
            <th>Billing Name</th>
            <th>Billing Code</th>
            <th>Billing City</th>
            <th>Billing Address</th>
            <th>Billing Zip</th>
            <th>Billing Region</th>
            <th>Billing Country</th>
          </tr>
          </thead>
          <tbody>
          <!-- Listado de documentos escaneados -->
          <tr v-for="(d, index) in data"
              :key="index">
            <td>
              <i @click="data.splice(index, 1); saveData()"
                 class="fa-regular fa-circle-xmark cursor-pointer me-3"></i>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.first_name"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.last_name"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.second_last_name"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.city"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.address"/>
            </td>
            <td>
              <select class="custom-input grey darken-4 white-text"
                      style="width: 150px"
                      v-model="d.document_country">
                <option v-for="(name, code) in countryCodesList"
                        :key="code"
                        :value="code">{{name}}</option>
              </select>
            </td>
            <td>
              <select class="custom-input grey darken-4 white-text" v-model="d.document_type">
                <option value="I">ID/DNI</option>
                <option value="P">Pasaporte</option>
                <option value="C">Permiso de Conducir</option>
                <option value="N">Permiso de residencia</option>
              </select>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.document_number"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.document_issue"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.document_expiry"/>
            </td>
            <td>
              <select class="custom-input grey darken-4 white-text"
                      style="width: 150px"
                      v-model="d.nationality">
                <option v-for="(name, code) in countryCodesList"
                        :key="code"
                        :value="code">{{name}}</option>
              </select>
            </td>
            <td>
              <select class="custom-input grey darken-4 white-text" v-model="d.gender">
                <option value="M">Hombre</option>
                <option value="F">Mujer</option>
              </select>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.birth_date"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.billing_name"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.billing_code"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.billing_city"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.billing_address"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.billing_zip"/>
            </td>
            <td>
              <input type="text"
                     class="custom-input grey darken-4 white-text"
                     v-model="d.billing_region"/>
            </td>
            <td>
              <select class="custom-input grey darken-4 white-text"
                      style="width: 150px"
                      v-model="d.billing_country">
                <option v-for="(name, code) in countryCodesList"
                        :key="code"
                        :value="code">{{name}}</option>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import ModalPolicia from "@/components/ModalPolicia.vue";

import WebCam from "./WebCam";
import axios from 'axios';
import InstruccionesView from "@/components/HomePage/InstruccionesView.vue";
import DownloadCsv from "@/components/Scanner/DownloadCsv.vue";
import countryCodes, {countryCodesList} from "@/countryCodes";

export default {
  props: [],

  components: {DownloadCsv, InstruccionesView, ModalPolicia, WebCam},

  name: 'DocumentScannerDemo',

  data() {
    return {
      type: 'id',
      images: [null, null],
      index_photo: 0,
      data: [],
      status: 0,

      errorMessage: '',
      apiKey: null,
      lock_watch: false
    }
  },

  watch: {
    apiKey() {
      // Save in local storage
      localStorage.setItem('apiKey', this.apiKey);
    },
    // Deep Watch data and save
    data: {
      handler() {
        if (!this.lock_watch) {
          this.saveData();
        }
      },
      deep: true
    }
  },

  computed: {
    countryCodesList() {
      return countryCodesList
    },
    canSend() {
      if (this.type === 'id') {
        if (this.images[0] === null || this.images[1] === null) {
          return false;
        }
      } else {
        if (this.images[0] === null) {
          return false;
        }
      }

      return true;
    }
  },

  methods: {
    countryCodes,
    addLine() {
      this.data.push({
        first_name: null,
        last_name: null,
        second_last_name: null,
        city: null,
        address: null,
        document_country: null,
        document_type: null,
        document_number: null,
        document_issue: null,
        document_expiry: null,
        nationality: null,
        gender: null,
        birth_date: null,
        billing_name: null,
        billing_code: null,
        billing_city: null,
        billing_address: null,
        billing_zip: null,
        billing_region: null,
        billing_country: null
      });
    },
    limpiar() {
      this.data = [];
      localStorage.setItem('data', JSON.stringify(this.data));
    },
    restore() {
      this.images = [null, null];
      this.index_photo = 0;
    },
    takePhoto(photo) {
      if (this.type === 'id') {
        this.images[this.index_photo] = photo;

        if (this.index_photo === 1) {
          this.index_photo = 0;
        } else {
          this.index_photo = 1;
        }
      } else {
        this.images[this.index_photo] = photo;
      }
      this.$forceUpdate();
    },
    sentOcr() {
      let formData = new FormData();

      if (this.type === 'id') {
        if (this.images[0] === null || this.images[1] === null) {
          return;
        }
      } else {
        if (this.images[0] === null) {
          return;
        }
      }

      this.status = 1;

      formData.append('type', this.type);
      if (this.images[0] !== null) {
        formData.append('image_frontal', this.DataURIToBlob(this.images[0]));
      }
      if (this.images[1] !== null) {
        formData.append('image_trasera', this.DataURIToBlob(this.images[1]));
      }

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${this.apiKey}`
      };

      axios.post(`${process.env.VUE_APP_API_URL}/document-ocr/scan`, formData, {headers: headers}).then(resp => {
        this.status = 2;

        if (resp.data.status === 'error') {
          this.errorMessage = resp.data.data;
          this.status = 3;
          return;
        }

        if (resp.data.status === 'success') {
          this.errorMessage = null;

          this.data.push(resp.data.data);
          this.saveData();

          setTimeout(() => {
            this.status = 0;
            this.images = [null, null];
            this.index_photo = 0;
          }, 2000);
        }
      }).catch(() => {
        this.status = 3;
      });
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], {type: mimeString})
    },
    saveData() {
      localStorage.setItem('data', JSON.stringify(this.data));
    }
  },

  created() {
    this.apiKey = localStorage.getItem('apiKey');

    this.lock_watch = true;
    // Load Data from localstorage
    const data = localStorage.getItem('data');

    if (data !== null && data !== undefined && data !== 'null') {
      this.data = JSON.parse(data);
    }

    this.lock_watch = false;
  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>